

export default {

    deliverCsv(finalCsvText, fileName) {
        let blob = new Blob([
            new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM needed for excel to interpret as UTF-8
            finalCsvText
        ],{ type: 'text/csv;charset=utf-8;' });
        let link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);  // required for FF
        link.click();
    }

}